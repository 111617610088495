<template>
  <div class="flex flex-col h-screen">
    <header class="no_print w-full h-14 px-4 border-b bg-white flex items-center flex-shrink-0">
      <div class="flex items-center justify-between h-full w-full">
        <div class="flex items-center space-x-2 h-full relative flex-grow">
          <vs-button class="exitBtn" @click="close" border>
            <svg-icon icon-class="arrow_left" className="primary xs"></svg-icon>
          </vs-button>
          <div class="exitSpan">
            <span>離開</span>
          </div>
          <h1 class="themeTitle text-limit-1">列印揀貨單</h1>
        </div>
        <vs-button success size="large" @click="print">列印</vs-button>
      </div>
    </header>

    <main>
      <section class="slip_container">
        <!-- 揀貨單 -->
        <div class="slip_print no_break">
          <!-- 商店名稱或logo -->
          <!-- <section class="flex items-center justify-center">
            <img style="max-width: 200px" src="https://picsum.photos/300/200/?random=10" />
            <h1>商店名稱</h1>
          </section> -->

          <section class="flex justify-between">
            <section>
              <h1>揀貨單</h1>
            </section>
          </section>
          <!-- 商品列表 -->
          <section>
            <table>
              <thead>
                <tr>
                  <th style="width: 350px" class="text-left">品項</th>
                  <th>庫存數</th>
                  <th>銷售數量</th>
                  <th>銷售金額</th>
                  <th>(已付)</th>
                  <th>(已付)</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-border">
                <tr v-for="item in selectedSlips" :key="item" class="space-x-4">
                  <td class="flex items-center space-x-2 text-left">
                    <div class="w-14 h-14 overflow-hidden flex-shrink-0">
                      <img class="object-cover" :src="item.img" />
                    </div>
                    <div>
                      <p>{{ item.title }}</p>
                      <p>貨號: {{ item.sku }} </p>
                      <p>條碼: {{ item.code }}</p>
                      <p>規格: {{ item.spec }}</p>
                    </div>
                  </td>
                  <td style="width: 100px" v-if="item.stock_track == 1">{{ item.snum | currency }}</td>
                  <td style="width: 100px" v-else>未追蹤庫存</td>
                  <td style="width: 110px">{{ item.onums1 | currency }}</td>
                  <td style="width: 110px">NT${{ item.opays1 | currency }}</td>
                  <td style="width: 80px" v-if="item.onums1 == item.onums2">{{ item.onums2 | currency }}</td>
                  <td style="width: 80px" v-else><font color="red">{{ item.onums2 | currency }}</font></td>
                  <td style="width: 80px" v-if="item.opays1 == item.opays2">NT${{ item.opays2 | currency }}</td>
                  <td style="width: 80px" v-else><font color="red">NT${{ item.opays2 | currency }}</font></td>
                </tr>
              </tbody>
            </table>
          </section>

        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: 'report_products_slip',
  data() {
    return {
      selectedSlips: [], // 選中的
    }
  },
  created() {
    let spsnos = this.$route.query.spsnos
    let start_date = this.$route.query.start_date
    let end_date = this.$route.query.end_date
    const res = Promise.all([this.getproductSalesList(spsnos, start_date, end_date)])
  },
  methods: {
    close() {
      window.close()
    },
    print() {
      // 先儲存設定再列印
      window.print()
    },
    // 3.1.1.1 商品列表-讀取
    getproductSalesList(spsnos, start_date, end_date) {
      return this.$axios({
        url: 'front/store/report/getProductSalesSelectedList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          spsnos: spsnos,
          start_date: start_date,
          end_date: end_date
        },
      }).then((res) => {
        if (res.data.Success) {
          this.selectedSlips = res.data.Data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
        return res.data.Success
      })
    },
  },
}
</script>

<style lang="scss" scoped>
main {
  @apply w-full flex  bg-gray-background;
  height: calc(100% - 56px);
}
.nav {
  @apply flex flex-col text-sm overflow-hidden transform translate-x-0 border-r w-80 fixed text-gray-text_light bg-gray-background;
  height: calc(100% - 56px);
  @media (max-width: 1024px) {
    // transform: translateX(-100%);
    @apply transition-transform duration-500 ease-in-out h-full left-0 top-0 z-500 -translate-x-full;
  }

  .nav_body {
    @apply space-y-4 h-full overflow-y-auto overflow-x-hidden p-4;
  }
}

.slip_container {
  @apply w-full h-full relative p-4 overflow-auto space-y-10;
  @screen lg {
    width: calc(100% - 320px);
    left: 180px;
  }

  .slip_print {
    @apply bg-white rounded-xl p-10 shadow-xl m-auto space-y-10;
    width: 800px;

    h1,
    h2 {
      @apply mb-2;
    }

    h1 {
      @apply text-2xl font-bold text-gray-text_dark;
    }

    h2,
    tr {
      @apply text-base font-bold text-gray-text_dark;
    }

    p,
    td {
      @apply text-sm text-gray-text_light font-normal;
    }

    table {
      @apply w-full text-right;

      th {
        padding-right: 24px;
        &:last-child {
          padding-right: 0px;
        }
      }

      td {
        @apply py-4;
        padding-right: 24px;
        &:last-child {
          padding-right: 0px;
        }
      }
    }
  }
}

// hover離開按鈕時顯示文字
.exitSpan {
  @apply border-r h-full pr-2 flex items-center transition-all  absolute transform translate-x-0 opacity-0 duration-200 ease-in-out text-sm;
}
.themeTitle {
  @apply text-gray-text_dark text-xl font-medium relative transition-transform  transform translate-x-0 duration-200 ease-in-out;
}

.exitBtn:hover ~ .exitSpan {
  @apply translate-x-10 opacity-100;
}
.exitBtn:hover ~ .themeTitle {
  @apply translate-x-14;
}

.checkbox_container {
  @apply w-full border rounded-xl transition-colors flex bg-white;
}
.checkbox_isShow_container {
  @apply border rounded-xl transition-colors flex bg-white;
}

::v-deep .vs-checkbox-label,
::v-deep .vs-radio__label {
  width: 100%;
  display: block;
  color: var(--gray-dark);
}

@media print {
  @page {
    margin: 0.5cm;
    size: A4 portrait;
  }

  * {
    -webkit-print-color-adjust: exact !important;
    font-family: Arial, Helvetica, sans-serif;
  }

  .no_print {
    @apply hidden;
  }

  main {
    background: #fff;
  }
  .slip_container {
    overflow: visible;
  }

  .slip_print {
    box-shadow: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .no_break {
    break-inside: avoid;
  }
}
</style>
